module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NTW7353","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","app_name":"aspendental-marketing","site_section":"aspendental-marketing","app_version":"3.0.1","design_system_version":"1.125.2","build_date":"2024-05-03T21:43:40.414Z"}},"routeChangeEventName":"gatsby_page_view","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aspendental.com Marketing Pages (/)","short_name":"aspendental-marketing","start_url":"/","background_color":"#ffffff","theme_color":"#002954","display":"standalone","orientation":"any","icon":"src/images/aspendental-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30ff9c70fc1bb0e28a6027d3ecd0a61b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-NTW7353","cookieName":"gatsby-gdpr-google-tagmanager"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
